import { useQuery, useQueryClient } from 'react-query';
import { useContext, useState } from 'react';
import { StoreContext } from '../store';
import axios from 'axios';
import { login } from '../api';
import httpClient from '../interceptors';

import { getRadius } from '../services';

const walkspan_api = process.env.REACT_APP_API_URL;
const getLifeStyleEssentials = async (
  lat,
  lng,
  type,
  area = 'quarter-mile',
  email,
) => {
  try {
    const getType = type === 'leisure' ? 'entertainment-fitness' : type;

    const url = `${walkspan_api}/lifestyle-essentials/?areatype=${area}&lat=${lat}&lng=${lng}&type=${getType}&email=${email}`;
    const response = await httpClient.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (error.response.status === 401) {
        return error.response.data;
      }
    } else {
      console.error('An error occurred:', error.message);
      return { error: '50x' }; // General 50x error indicator
    }
  }
};

const useGemMarkers = (
  lat,
  lng,
  type,
  apiKey,
  area,
  signal,
  serviceRef,
  mapCenter,
  distance,
  index = 0
) => {
  const queryClient = useQueryClient();
  const { dispatch, state } = useContext(StoreContext);
  const { userDetails, LSEmail, isGooglePlacesLoaded } = state;
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);

  return useQuery(
    ['lifestyleEssentials', lat, lng, type, area],
    async () => {
      dispatch({ type: 'SET_IS_GOOGLE_PLACES_LOADED', payload: true });
      // Fetch API data
      let apiData = await getLifeStyleEssentials(
        lat,
        lng,
        type,
        area,
        userDetails?.email || LSEmail
      );

      if (apiData.Error === 'Unauthorized' && !isRefreshingToken) {
        setIsRefreshingToken(true);
        const newToken = await login();
        dispatch({ type: 'SET_TOKEN', payload: newToken });
        setIsRefreshingToken(false);

        apiData = await getLifeStyleEssentials(
          lat,
          lng,
          type,
          area,
          userDetails?.email || LSEmail,
          signal
        );
        queryClient.setQueryData(
          ['lifestyleEssentials', lat, lng, type, area],
          apiData
        );
      }

      // Fetch Google Places results (wait for all paginated results)
      let googlePlacesResults = [];
      if (serviceRef?.current && mapCenter) {
        try {
          googlePlacesResults = await handleUnifiedSearch(
            serviceRef,
            type,
            mapCenter,
            getRadius(distance),
            dispatch
          );
        } catch (error) {
          console.error('Google Places API Error:', error);
        }
      }

      // Merge API and Google results
      const combinedResults = [];
      const findMatchingEntry = (item, list) =>
        list.find(
          (g) =>
            (g.vicinity &&
              item.address &&
              g.vicinity.toLowerCase() === item.address.toLowerCase()) ||
            (g.geometry?.location?.lat === item.lat &&
              g.geometry?.location?.lng === item.lng)
        );

      apiData.forEach((item) => {
        const match = findMatchingEntry(item, googlePlacesResults);
        if (match) {
          combinedResults.push({
            id: item.id || match.place_id,
            name: item.name || match.name,
            address: item.address || match.vicinity,
            lat: item.lat || match.geometry?.location?.lat(),
            lng: item.lng || match.geometry?.location?.lng(),
            category: item.category,
            is_24_7: item.is_24_7 || false,
            is_preferred: item.is_preferred || false,
            opening_hours:
              item.opening_hours ||
              (match.opening_hours?.weekday_text?.join(', ') || null),
            type: item.type,
            rating: match.rating || null,
            user_ratings_total: match.user_ratings_total || null,
            google_place_id: match.place_id || null,
            google_icon: match.icon || null,
            source: 'combined',
          });
          googlePlacesResults = googlePlacesResults.filter(
            (g) => g.place_id !== match.place_id
          );
        } else {
          combinedResults.push({ ...item, source: 'api' });
        }
      });

      googlePlacesResults.forEach((place) => {
        combinedResults.push({
          id: place.place_id,
          name: place.name,
          address: place.vicinity,
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
          category: place.types?.[0]?.toca,
          is_24_7: false,
          is_preferred: false,
          opening_hours:
            place.opening_hours?.weekday_text?.join(', ') || 'Unknown',
          type: categorizePlaceType(place.types),
          rating: place.rating || null,
          user_ratings_total: place.user_ratings_total || null,
          google_place_id: place.place_id,
          google_icon: place.icon || null,
          source: 'google',
        });
      });

      const categoryCounts = combinedResults.reduce(
        (acc, place) => {
          const category = place.type || 'shops';
          acc[category] = (acc[category] || 0) + 1;
          return acc;
        },
        { food: 0, services: 0, shops: 0, transit: 0, leisure: 0 }
      );

      dispatch({
        type: 'SET_LIFESTYLE_SCORES',
        payload: { scoreData: categoryCounts, scoreIndex: index },
      });

      return combinedResults;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!lat && !!lng && !!type,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );
};


const fetchInsights = async (mapCenter, type) => {
  const insightsUrl = "https://areainsights.googleapis.com/v1:computeInsights";
  const apiKey = "AIzaSyAmcGjLChegfkj9yJZBMKhlk18ckavo3HI";

  const insightsRequest = {
    insights: ["INSIGHT_COUNT", "INSIGHT_PLACES"],
    filter: {
      locationFilter: {
        circle: {
          latLng: {
            latitude: mapCenter.lat,
            longitude: mapCenter.lng,
          },
          radius: 400, // Search within 5km radius
        },
      },
      "typeFilter": { "includedTypes": "restaurant" }
    },
  };

  try {
    const response = await fetch(insightsUrl, {
      method: "POST",
      headers: {
        "X-Goog-Api-Key": apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(insightsRequest),
    });

    if (!response.ok) {
      throw new Error(`Insights API error: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Insights Data:", data);
    return data.results || []; // Adjust this based on the actual response structure
  } catch (error) {
    console.error("Error fetching Insights:", error);
    return [];
  }
};

const handleUnifiedSearch = async (serviceRef, type, mapCenter, radius = 402.43, dispatch) => {
  console.log(radius)
  if (!serviceRef.current) {
    console.error("PlacesService is not initialized.");
    return [];
  }

  const nearbyRequest = {
    location: mapCenter,
    radius: radius, // Search within provided radius
    type, // For Nearby Search
  };

  const textRequest = {
    query: type, // For Text Search
    location: mapCenter,
    radius: radius, // Search within 5km radius
  };

  const fetchAllPages = (searchFunction, request) => {
    return new Promise((resolve, reject) => {
      let allResults = [];

      const processResults = (res, status, pagination) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          allResults = [...allResults, ...res];

          if (pagination && pagination.hasNextPage) {
            console.log("Fetching next page...");
            setTimeout(() => pagination.nextPage(), 2000); // Allow time for next page
          } else {
            resolve(allResults);
          }
        } else if (status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          resolve([]); // No results found
        } else {
          console.error("Google Places API error:", status);
          reject(status);
        }
      };

      searchFunction(request, processResults);
    });
  };

  try {
    const [nearbyResults, textResults] = await Promise.all([
      fetchAllPages(serviceRef.current.nearbySearch.bind(serviceRef.current), nearbyRequest),
      fetchAllPages(serviceRef.current.textSearch.bind(serviceRef.current), textRequest),
    ]);

    const unifiedResults = [...nearbyResults, ...textResults];
    dispatch({ type: 'SET_IS_GOOGLE_PLACES_LOADED', payload: false });
    console.log("Unified Results:", unifiedResults);
    return unifiedResults;
  } catch (error) {
    console.error("Error during search:", error);
    return [];
  }
};



const categorizePlaceType = (googleTypes) => {
  if (!googleTypes || googleTypes.length === 0) return "Unknown";

  const typeMapping = {
    food: ["restaurant", "cafe", "bakery", "bar", "meal_takeaway", "meal_delivery"],
    services: ["bank", "hospital", "pharmacy", "doctor", "laundry", "beauty_salon", "car_repair", "locksmith", "lawyer", "electrician", "insurance_agency"],
    shops: ["shopping_mall", "clothing_store", "shoe_store", "supermarket", "grocery_or_supermarket", "department_store", "book_store", "convenience_store", "electronics_store", "furniture_store", "hardware_store", "jewelry_store", "liquor_store", "pet_store"],
    transit: ["bus_station", "subway_station", "train_station", "taxi_stand", "transit_station", "airport", "parking"],
    leisure: ["amusement_park", "aquarium", "art_gallery", "bar", "casino", "movie_theater", "museum", "night_club", "park", "stadium", "zoo", "spa"]
  };

  for (const [category, types] of Object.entries(typeMapping)) {
    if (googleTypes.some((type) => types.includes(type))) {
      return category;
    }
  }

  return "shops"; // Default if no match is found
};

export default useGemMarkers;
