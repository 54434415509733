import React, { useContext, useCallback, useRef } from 'react';
import { GoogleMap } from '@react-google-maps/api';

import { lifeStyleFeatures, walkIndexFeatures } from "../../constants";
import ProgresBar from './Bar';
import './index.css';
import { StoreContext } from '../../store.js';
import Show from '../../utility/Show.jsx';
import {
  getLimit,
  getLifeStyleWording,
  getWalkIndexWording,
  getTypeDescription,
  getOverallFeelScore,
  lifeStyleFeaturesScores,
  isFeelScoreEmpty,
  getSoloFeelScore,
  hexToRgb,
  getArea,
} from '../../services';
import { WalkingDistanceButtons } from '../WalkingDistanceButtons/WalkingDistanceButtons.jsx';
import { ReactComponent as Back } from '../../assets/landing-page/back-button.svg';
import Skeleton from '../Skeleton/index.jsx';
import { Tooltip } from 'react-tooltip';
import { getActiveIcon } from '../NeighborhoodScore/index.jsx';
import { Autocomplete } from '../../utility/Autocomplete.jsx';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import NSVersion3 from '../NeighborhoodScore/version3.jsx';
import { WalkingLoader } from '../Loaders/index.jsx';

import useGemMarkers from '../../hooks/useGemMarkers.js';
import { onLoad } from '@sentry/react';

const DiscoverNeighborhood = ({ overallScore, openEstablishments }) => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    currentAddress,
    lifeStyleScores,
    walkindexScores,
    currentDistance,
    isStandalone,
    isMobile,
    showCompareLifeStyle,
    isModern,
    isLoading,
    isEmbedMode,
    clientName,
    currentTab,
    apiKey,
    isGooglePlacesLoaded,
  } = state;


  const controller = new AbortController();
  const signal = controller.signal;
  const mapRef = useRef(null);
  const serviceRef = useRef();

  const {
    data: gemMarkers,
    error: gemMarkersError,
    isLoading: gemMarkersLoading,
  } = useGemMarkers(
    currentAddress?.latitude,
    currentAddress?.longitude,
    'all',
    apiKey,
    getArea(currentDistance),
    signal,
    serviceRef, 
    { lat: currentAddress?.latitude, lng: currentAddress?.longitude },
    currentDistance,
    0,
  );

  const isWalkIndex = currentTab === 'walkindex';
  const currentFeelScores = walkindexScores[0] || {};
  const isGemsEmpty = !lifeStyleScores || Object.keys(lifeStyleScores[0] || {}).length === 0;

  const getWalkableTitle = (score) => getWalkIndexWording(score);
  const lifeStyleEssentialScores = () => lifeStyleFeaturesScores(lifeStyleScores, 0, currentDistance, isStandalone);
  const getWalkIndexOverallScore = () => getOverallFeelScore(currentFeelScores);

  const getGemsOverallScore = () => {
    const scores = Object.values(lifeStyleEssentialScores());
    const counts = [0, 0, 0];

    scores.forEach((score) => {
      const roundedScore = Math.round(score);
      counts[roundedScore - 1]++;
    });

    if (counts[2] >= 1) return 3;
    if (counts[1] >= 1) return 2;
    return 1;
  };

  const getWalkableDescription = (score, type) => {
    const limit = getLimit(currentDistance);
    const isWalkIndex = type === 'walkindex';
  
    if (isWalkIndex) {
      switch (score) {
        case 1:
          return 'Low presence of features <25%';
        case 2:
          return 'Moderate presence of features >25% <50%';
        case 3:
          return 'High presence of features >50%'
        default:
          return '';
      }
    } else {
      switch (score) {
        case 1:
          return (
            <>
              {`< ${limit[0]} LE`} <br /> living essentials
            </>
          );
        case 2:
          return (
            <>
              {`< ${limit[1]} LE`} <br /> living essentials
            </>
          );
        case 3:
          return (
            <>
              {`> ${limit[2]} LE`} <br /> living essentials
            </>
          );
        default:
          return '';
      }
    }
  };

  const walkindxRadius = (score) => (score === 1 ? '<25%' : score === 2 ? '>25% <50%' : '>50%');
  const filteredLifeStyleFeatures = lifeStyleFeatures.filter((feature) => feature.id !== 'All' && feature.id !== 'Favorites');

  const renderGemScores = () => {
    if (!lifeStyleScores) return null;
    return <ProgresBar items={filteredLifeStyleFeatures} scores={lifeStyleScores[0]} itemClick={redirectToFeelFeature} />;
  };

  const getFeelScore = (id) => getSoloFeelScore(currentFeelScores[id]);
  const getFeelItemStatus = (id) => (getFeelScore(id) >= 3 ? '' : 'inactive-feature');

  const getFeelDescription = (feature, score) =>
    getTypeDescription('walkindex', feature?.toLowerCase(), score, currentDistance, isStandalone, false);

  const redirectToMap = () => {
    dispatch({ type: 'SET_PREVIOUS_PAGE', payload: 1 });
    if (isMobile || isEmbedMode) actions.toggleFullScreenMap();
    dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });

    if (isWalkIndex) {
      dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
    }

    if (clientName) actions.toggleFullScreenMap();
  };

  const redirectToFeelFeature = (feature, isWalkIndex) => {
    redirectToMap()

    if (isWalkIndex) {
      dispatch({ type: 'SET_CURRENT_TAB', payload: 'walkindex' });
    } else {
      if (currentTab !== 'lifestyle') {
        dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
      }
    }

    dispatch({ type: 'SET_MAP_TAB', payload: feature });
  }

  const toggleCompareVisibility = () => {
    dispatch({ type: 'SET_PREVIOUS_PAGE', payload: 1 });
    if (isMobile || isEmbedMode) actions.toggleFullScreenMap();
    dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: !showCompareLifeStyle });
    dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
    dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: true });
    dispatch({ type: 'SET_SHOW_DASHBOARD', payload: false });
    if (isModern) dispatch({ type: 'SET_SHOW_COMBINE_SEARCH', payload: true });
    dispatch({ type: 'SET_MAP_TAB', payload: 'All' });
  };

  const getMinutes = () => {
    switch (currentDistance) {
      case '1': return 5;
      case '2': return 10;
      case '3': return 20;
      default: return;
    }
  };

  const getBgColor = () => {
    const branding = actions.getBrandingColor();
    const brandingColor = branding["--color"];
    
    // if (brandingColor === '#000000') {
    //   return { '--color': '#fff' };
    // }
    
    return { '--color': brandingColor };
  };
  
  const getTextColor = () => {
    const bgColor = getBgColor()['--color'];
  
    if (bgColor === '#fff') {
      return { '--text-color': '#000' };
    }
  
    return { '--text-color': '#fff' };
  };

  const gotoSearchAddress = () => {
    dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: true });
  };

  const onLoad = useCallback(function callback(map1) {
    map1.setMapTypeId('satellite');
    dispatch({ type: 'SET_CURRENT_MAPTYPE', payload: 'satellite' });

    if (!showCompareLifeStyle) {
      dispatch({ type: 'SET_MAP_INSTANCE', payload: map1 });
    } else {
      dispatch({ type: 'SET_COMPARE_MAPS', payload: { index: 0, object: map1 } });
    }

    serviceRef.current = new window.google.maps.places.PlacesService(map1);

  }, []);

  return (
    <div 
      className="discover-neighborhood relative"
      style={{
        ...getBgColor(),
        ...getTextColor()
      }}
    >
      <GoogleMap
        mapContainerStyle={{ height: '100%', width: '100%', display: 'none' }}
        center={{
          lat: parseFloat(currentAddress?.latitude),
          lng: parseFloat(currentAddress?.longitude),
        }}
        onLoad={onLoad}
      ></GoogleMap>
      <div className="discover-header-container">
        <div className='flex items-start gap-x-2'>
          <Show.When isTrue={!clientName}>
            <Back
              className='discover-back mt-1'
              onClick={() => gotoSearchAddress()}
            />
          </Show.When>
          <div className='flex flex-col gap-y-1'>
            <h1 className='text-2xl font-bold tracking-wider'>DISCOVER THE NEIGHBORHOOD</h1>
            <Autocomplete
              className='summary-autocomplete'
              placeholder='Type an address here'
              value={currentAddress?.property}
              icon={SearchIcon}
            />
            {/* <h2 className="discover-address">{currentAddress?.property}</h2> */}
            <span>{getMinutes()} min walking radius</span>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <span> Walking Distance</span>
          <WalkingDistanceButtons isTitle={false} showTitle={false} key="dashboard-walking-distance-btn" />
        </div>
      </div>

      {/* GEMS SCORE */}
      <div className="summary-parent-container">
        <Show>
          <Show.When isTrue={!isLoading || !isGemsEmpty}>
            <div className="gems-score-container">
              <div className="gem-score-summary">
              <h2 className="absolute -top-10 left-1/2 transform -translate-x-1/2 text-center text-lg tracking-wider font-medium">GEMS</h2>
                <NSVersion3 isWalkIndex={false} score={getGemsOverallScore()} width="55px" fontSize="18px"/>
                {/* {getActiveIcon(overallScore, 'lifestyle')}
                <h2 className="score-title">{getWalkableTitle(getGemsOverallScore())}</h2> */}
                {/* <span className="score-description">{getWalkableDescription(getGemsOverallScore(), 'lifestyle')}</span> */}
              </div>
              <div className='w-full'>
                {renderGemScores()}
              </div>
            </div>
          </Show.When>
          <Show.Else>
            <div className="gems-score-container">
              <Skeleton count={1} size={55} />
            </div>
          </Show.Else>
        </Show>
      </div>

      {/* Character Score */}
      <Show.When isTrue={!isStandalone}>
        <Show.When isTrue={isLoading}>
          <div className="feel-score-container">
            <Skeleton count={1} size={55} />
          </div>
        </Show.When>
        <Show.When isTrue={!isLoading && !isFeelScoreEmpty(currentFeelScores)}>
        <div className="summary-parent-container mt-8">
          <div className="feel-score-container">
            <div className="gem-score-summary">
              <h2 className="absolute -top-10 left-1/2 transform -translate-x-1/2 text-center text-lg tracking-wider">CHARACTER</h2>
              <NSVersion3 isWalkIndex={true} score={getWalkIndexOverallScore()} width="55px" fontSize="18px"/>
              {/* {getActiveIcon(getWalkIndexOverallScore(), 'walkindex')}
              <h2 className="score-title">{getWalkableTitle(getWalkIndexOverallScore())}</h2> */}
              <span className="score-description">
                {/* {getWalkableDescription(getWalkIndexOverallScore(), 'walkindex')} */}
                {/* <span>{walkindxRadius(getWalkIndexOverallScore())}</span> */}
              </span>
            </div>
            <div className="feel-details w-full">
              {walkIndexFeatures.map((feature) => (
                <div
                  key={feature.id}
                  className={`feel-feature ${getFeelItemStatus(feature.id)}`}
                  style={{ '--icon-color': hexToRgb(feature.color) }}
                  data-tooltip-id={`feel-score-${feature.name}`}
                  onClick={()=> redirectToFeelFeature(feature.name, true)}
                >
                  {feature.svg}
                  <h3>{feature.name}</h3>
                  <span>{getFeelDescription(feature.name, getFeelScore(feature.id))}</span>
                  {/* <Tooltip
                    className='map-control-btn-popup'
                    id={`feel-score-${feature.name}`}
                    place='bottom'
                  >
                    {getFeelScore(feature.id)}
                  </Tooltip> */}
                </div>
              ))}
            </div>
          </div>
        </div>
        </Show.When>
      </Show.When>


      {/* ACTION BUTTONS */}
      <div className="action-buttons">
        <button className="view-maps" onClick={redirectToMap}>VIEW MAPS</button>
        <button className="quick-search" onClick={openEstablishments}>QUICK SEARCH</button>
        <button className="compare-addresses" onClick={toggleCompareVisibility}>COMPARE ADDRESSES</button>
      </div>
      <Show.When isTrue={isGooglePlacesLoaded}>
        <WalkingLoader blurValue='2.5px' />
      </Show.When>
    </div>
  );
};

export default DiscoverNeighborhood;
