import { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';

import Logo from '../../assets/walkspan-logo-2.svg';
import { ReactComponent as LinkedIn } from '../../assets/social-icons/linkedin-black.svg';
import { ReactComponent as Instagram } from '../../assets/social-icons/instagram-icon.svg';
import { ReactComponent as Twitter } from '../../assets/social-icons/twitterx-icon.svg';

import { StoreContext } from '../../store';

const Header = () => {
  const { navigate } = useContext(StoreContext);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navLinks = [
    { path: '/home', label: 'Home' },
    { path: '/about-us', label: 'About Us' },
    { isDropdown: true, label: 'Services', path: '/services', items: [
        { path: '/services/real-estate', label: 'Real Estate' },
        { path: '/services/travel', label: 'Travel' },
        { path: '/services/20-min-city', label: '20-min City' },
      ]
    },
    { path: '/contact-us', label: 'Contact Us' },
  ];

  const isActive = (path) => location.pathname === path;

  const handleLinkClick = (link) => {
    navigate(link.path);
    document.title = `${link.label} - Walkspan`;
    setMenuOpen(false); // Close menu on link click
  };

  return (
    <div className='header'>
      <div className='header-top'>
        <img
          className='logo-mobile'
          src={Logo}
          onClick={() => handleLinkClick({ path: '/home', label: 'Home' })}
        />
        <div className='menu-icon' onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? '✖' : '☰'}
        </div>
      </div>
      <div className={`header-menu ${menuOpen ? 'open' : ''}`}>
        {navLinks.map((link, index) => {
          if (link.isDropdown) {
            return (
              <div
                key={index}
                className={`relative cursor-pointer ${isDropdownOpen ? 'active-header' : ''}`}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                {link.label} {isDropdownOpen ? '▲' : '▼'}
                {isDropdownOpen && (
                  <section className="mt-2 mx-auto w-48 text-center z-50 py-1">
                    {link.items.map((item) => (
                      <div
                        key={item.path}
                        onClick={() => {
                          setIsDropdownOpen(false);
                          handleLinkClick(item);
                        }}
                        className="text-sm py-2 text-gray-600 hover:bg-gray-100 cursor-pointer"
                      >
                        {item.label}
                      </div>
                    ))}
                  </section>
                )}
              </div>
            );
          } else {
            return (
              <div
                key={link.path}
                onClick={() => handleLinkClick(link)}
                className={`${isActive(link.path) ? 'active-header' : ''}`}
              >
                {link.label}
              </div>
            );
          }
        })}
        {/* <button
            onClick={() => navigate('/login')}
          className='login-btn'
        >
          login
        </button> */}
        <div className='header-socials'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.instagram.com/walkspan_nyc/'
          >
            <Instagram />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.linkedin.com/company/walkspan-inc'
          >
            <LinkedIn />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://twitter.com/walkspan_walks'
          >
            <Twitter />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
