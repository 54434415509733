import { useContext } from 'react';
import './index.css';

import { ReactComponent as Logo } from '../../assets/Walkspan-logo-whitefont.svg';
import { ReactComponent as Instragram } from '../../assets/social-icons/white-instagram.svg';
import { ReactComponent as LinkedIn } from '../../assets/social-icons/white-linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/social-icons/white-twitterx.svg';
import { ReactComponent as ArrowUp } from '../../assets/arrow-circle-up.svg';
import { StoreContext } from '../../store';

const Footer = () => {
  const { navigate } = useContext(StoreContext);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const gotoContactUs = () => {
    scrollToTop();
    navigate('/contact-us');
    document.title = 'Contact Us - Walkspan';
  };

  return (
    <div className='Footer'>
      <div className='footer-top'>
        <div className='footer-top-content'>
          <h1>WORK WITH US</h1>
          <button onClick={() => gotoContactUs()} className='landing-view-btn'>
            Request a Demo
          </button>
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='footer-left'>
          <Logo className='w-[250px] -ml-8'/>
          <div className='footer-company-info-wrapper'>
            <div className='footer-company-info'>
              <h2>Walkspan, Inc</h2>
              <p>
                189 West 89th Street, PH1L
                <br /> New York, NY 10014
              </p>
            </div>
            <div className='footer-contact-info'>
              <h2>Contact</h2>
              <p>
                Info@walkspan.com
                <br /> 914-419-0667
              </p>
            </div>
          </div>
        </div>
        <div className='footer-right'>
          <div onClick={() => scrollToTop()} className='footer-top-link'>
            Go to top page <ArrowUp />
          </div>
          <div>
            <div className='footer-social-media'>
              <a target='_blank' href='https://www.instagram.com/walkspan_nyc/'>
                <Instragram />
              </a>
              <a
                target='_blank'
                href='https://www.linkedin.com/company/walkspan-inc'
              >
                <LinkedIn />
              </a>
              <a target='_blank' href='https://twitter.com/walkspan_walks'>
                <Twitter />
              </a>
            </div>
            <p>Copyright © 2019 Walkspan Inc. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
